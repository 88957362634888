import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, PhoneIcon } from "@heroicons/react/20/solid";
import { Fragment, useEffect, useRef } from "react";
import { gsap } from "gsap";
import PrintPre from "./print-pre";
import ButtonNeoGen from "./button-neogen";
import { API_URL } from "../services/api.service";

const solutions = [
    {
        name: "Talk to Support",
        description: "Have your rep call you",
        href: "##",
        icon: IconThree,
    },
    {
        name: "Chat with Support",
        description: "Chat online with our support team",
        href: "##",
        icon: IconTwo,
    },
    // {
    //     name: "Reports",
    //     description: "Keep track of your growth",
    //     href: "##",
    //     icon: IconThree,
    // },
];

type PopoverComponentProps = {
    // title: string;
    document: string;
};

export default function PopoverComponent(props: PopoverComponentProps) {
    const tl = useRef<GSAPTimeline | null>(null);

    useEffect(() => {
        const dots = document.getElementsByClassName("help-button");
        if (tl && dots.length > 0) {
            tl.current = gsap.timeline({ paused: false, repeat: -1, yoyo: true });
            // tl.current.set(dots, { stroke: "rgb(0, 254, 255)" });
            // tl.current.to(".dot", { duration: 2, opacity: 0});
            // tl.current.set(dots, {duration: 1,  color: "rgba(125, 200, 250, 0)" , backgroundColor: "rgb(255,255,255)", ease: "none" });

            tl.current.set(dots, { color: "rgba(100, 100, 255, 255)", ease: "none" });
            tl.current.to(dots, { duration: 1, color: "rgba(100, 200, 100, 100)", ease: "none" });
        }
        return () => {
            tl.current?.kill();
            tl.current = null;
        };
    }, []);
    return (
        // <div className="w-full max-w-sm ">
        <Popover className="relative inline">
            {({ open, close }) => (
                <>
                    <Popover.Button
                        className={`
                ${open ? "" : "text-opacity-90"}
                group inline-flex items-center
                    border px-2 py-1.5
                    rounded-lg
                    help-button
                    bg-gray-100
                    dark:bg-gray-900
                    dark:border-gray-700
                 text-white dark:text-gray-300
                hover:text-opacity-100 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-indigo-800
                dark:ring-indigo-800 dark:border-none dark:focus:ring-0 dark:focus:ring-offset-2
                 text-xs font-medium

                `}
                    >
                        <span className="help-button fa-xl fas fa-info-circle font-bold mr-2 " />
                        <span className="text-gray-400">More Info</span>
                    </Popover.Button>
                    <Popover.Overlay className="fixed inset-0 bg-black opacity-30 dark:opacity-80 z-10 " />
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="fixed z-10 mt-3 left-10  right-10 top-10 bottom-10 inline">
                            <div className="overflow-auto z-50  rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                {/* <div className="relative z-50  grid gap-10 bg-white p-7 lg:grid-cols-2">
                                    {solutions.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-m-3 flex z-50 items-center rounded-lg p-5 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                        >
                                            <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12 bg-orange-300 rounded-xl p-2">
                                                <item.icon aria-hidden="true" />
                                            </div>
                                            <div className="ml-4">
                                                <p className="text-sm font-medium text-gray-900">
                                                    {item.name}
                                                </p>
                                                <p className="text-sm text-gray-500">
                                                    {item.description}
                                                </p>
                                            </div>
                                        </a>
                                    ))}
                                </div> */}
                                <div className="bg-gray-50 p-10 dark:bg-gray-900">
                                    {/* <a
                                        href="##"
                                        className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                    > */}
                                    {/* <span className="flex items-center">
                                        <span className=" font-medium text-gray-900">
                                                Documentation
                                        </span>
                                    </span> */}
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: props.document.replace(
                                                /\[\[(.*?)\]\]/g,
                                                (_match: string, fieldFound: string) => {
                                                    // Replace [[filename]] with a link to the video
                                                    return `
                    <div className={" " + width + " mb-5"}>
                    <video  controls>
                        <source src=${API_URL + "/files/" + fieldFound} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                </div>
                        `;
                                                },
                                            ),
                                        }}
                                    />
                                    {/* <p className="block text-sm text-gray-500 my-5">

                                        <PrintPre>
                                            {props}
                                        </PrintPre>
                                    </p>
                                    <p className="block text-sm text-gray-500 my-5">
                                        This is how you bla. This is how you bla. This is how you bla. This is how you bla.
                                    </p>
                                    <p className="block text-sm text-gray-500 my-5">
                                        This is how you bla. This is how you bla. This is how you bla. This is how you bla.
                                    </p>
                                    <p className="block text-sm text-gray-500 my-5">
                                        This is how you bla. This is how you bla. This is how you bla. This is how you bla.
                                    </p> */}
                                    {/* </a> */}
                                    <ButtonNeoGen
                                        block
                                        type="outline-primary"
                                        className="mt-3"
                                        onClick={() => {
                                            close();
                                        }}
                                    >
                                        Close
                                    </ButtonNeoGen>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
        // </div>
    );
}

function IconOne() {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="8" fill="#FFEDD5" />
            <path d="M24 11L35.2583 17.5V30.5L24 37L12.7417 30.5V17.5L24 11Z" stroke="#FB923C" strokeWidth="2" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.7417 19.8094V28.1906L24 32.3812L31.2584 28.1906V19.8094L24 15.6188L16.7417 19.8094Z"
                stroke="#FDBA74"
                strokeWidth="2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.7417 22.1196V25.882L24 27.7632L27.2584 25.882V22.1196L24 20.2384L20.7417 22.1196Z"
                stroke="#FDBA74"
                strokeWidth="2"
            />
        </svg>
    );
}

function IconTwo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
            />
        </svg>
    );
}

function IconThree() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
            />
        </svg>
    );
}
